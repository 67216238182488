import React from "react";
import './LinkText.css';

function LinkText(props) {
    return (
        <div className="block">
            <img id="linkImg" src={props.icon} alt=""/>
            <a id="linkText" href={props.href}>{props.text}</a>
        </div>
    );
}

export default LinkText;