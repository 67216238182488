import React from 'react';
import { motion } from 'framer-motion';
import './About.css';

function About() {
    const bio = "I'm a software engineer, digital artist, and game developer. " +
    "I graduated from NYU in 2023 with a B.A. Computer Science and a minor in digital art & design. " +
    "I'm currently based in the New York City metropolitan area. " +
    "In my free time, I like to read, write, and hang out with my cat. Please check out some of my games and programs on the projects page.";
    return (
        <motion.div
            initial={{x: "50%"}}
            animate={{x: 0}}
            exit={{x: window.innerWidth}}
            transition={{type: "spring", bounce: 0.3, duration: 0.2}}
        >
            <h2>About</h2>
            <div className="aboutBlock">
                <h3 className="bio">Hi, I'm Charlotte Lin!</h3>
                <p className="bio">
                    {bio}
                    <br/><br/>
                    If you'd like to contact me, please email me at <a className="emailAddress" href="mailto:charlottexlin02@gmail.com">charlottexlin02@gmail.com</a>.
                </p>
            </div>
        </motion.div>
    );
}

export default About;