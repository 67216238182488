import greenTown from './img/greentown.png';
import gachaCats from './img/gachacats.PNG';
import personalWebsite from './img/website.PNG';
import catWent from './img/wherethecatwent.png';
import sliceOfLife from './img/sliceoflife.png';
import invasiveImpostor from './img/invasiveimpostor.jpg';
import mealspot from './img/mealspot.jpg';
import milkTeaShop from './img/milkteashop.jpg';
import taskPet from './img/taskpet.jpg';
import calmCafe from './img/calmcafe.png';
import witchyBrews from './img/witchyBrews.jpg';
import aLittlePlace from './img/aLittlePlace.png';
import githubIcon from './img/github.png';
import linkIcon from './img/chainlink.png';
import devpostIcon from './img/devpost.png';
import driveIcon from './img/drive.png';
import videoIcon from './img/videoIcon.PNG';

// Project images should be 350 x 350 px, descriptions can be up to 4 lines long
// Icons should be 28 x 28 px

const itemArr = [
    {
        image: aLittlePlace,
        projectName: "A Little Place in the Woods",
        featured: true,
        subtitle: "november 2023",
        techStack: "unity, c#",
        description:
            "Explore the forest to find furnishings for your 1:12 scale house. I created this game in one month for the GitHub Game Off jam!",
        links: [
            {icon: linkIcon, text: "charlottexlin.itch.io/a-little-place-in-the-woods", href: "https://charlottexlin.itch.io/a-little-place-in-the-woods"},
            {icon: githubIcon, text: "github.com/charlottexlin/a-little-place-in-the-woods", href: "https://github.com/charlottexlin/a-little-place-in-the-woods"},
            {icon: videoIcon, text: "YouTube demo", href: "https://www.youtube.com/watch?v=yR-7SmZI2BM"}
        ]
    },
    {
        image: witchyBrews,
        projectName: "Witchy Brews",
        featured: false,
        subtitle: "october 2023",
        techStack: "unity, c#",
        description:
            "Brew interesting potions in your cauldron and try to collect all twenty possibilities.",
        links: [
            {icon: linkIcon, text: "charlottexlin.itch.io/witchy-brews", href: "https://charlottexlin.itch.io/witchy-brews"}
        ]
    },
    {
        image: milkTeaShop,
        projectName: "Milk Tea Shop",
        featured: false,
        subtitle: "june 2023, web game",
        techStack: "javascript, html, css",
        description:
            "Run your own milk tea shop and serve as many customers as possible in this cute mini game.",
        links: [
            {icon: linkIcon, text: "charlottexlin.itch.io/milkteashop", href: "https://charlottexlin.itch.io/milkteashop"}
        ]
    },
    {
        image: mealspot,
        projectName: "Mealspot",
        featured: false,
        subtitle: "may 2023, web application",
        techStack: "mongodb, express, react, node, javascript, css, mui, spoonacular api",
        description:
            "Developed for the Agile and DevOps course at NYU, Mealspot is a mobile app used for budget-based meal planning. Search and save recipes pulled from the Spoonacular API, " +
            "create fridge and grocery lists, get recipe recommendations based on fridge ingredients, create a weekly meal plan, and see the price of your planned meals this week. " +
            "I worked with a team of four others to develop this app using Agile.",
        links: [
            {icon: githubIcon, text: "github.com/agiledev-students-spring-2023/final-project-mealspot", href: "https://github.com/agiledev-students-spring-2023/final-project-mealspot"},
            {icon: videoIcon, text: "Video Demo", href: "https://youtu.be/wCx-ancjk7w"}
        ]
    },
    {
        image: invasiveImpostor,
        projectName: "Invasive Impostor",
        featured: false,
        subtitle: "march 2023, web application",
        techStack: "express, html, css, twilio api",
        description:
            "Created for Hack@CEWIT 2023, Invasive Impostor is a social dedication party game that is played through text messages on your phone and allows players to " +
            "learn about how invasive species can outcompete native species. This website explains how to play and allows users to set up players’ phones to receive text messages from the game site. " +
            "This project won “Most Creative Use of Twilio”.", 
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/hack-cewit", href: "https://github.com/charlottexlin/hack-cewit"},
            {icon: devpostIcon, text: "devpost.com/software/invasive-impostor", href: "https://devpost.com/software/invasive-impostor"}
        ]
    },
    {
        image: greenTown,
        projectName: "Green Town",
        featured: false,
        subtitle: "february 2023, web game",
        techStack: "javascript, node.js, express.js, pixi.js",
        description:
            "Green Town is an educational web game about sustainable communities. You play as a town planner, making choices about how to build the town and learning from the consequences. " +
            "Art is from open source game assets. The game was created for HackNYU 2023 by me and one other team member. It received the “Most Outrageous Hack” prize.",
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/greentown", href: "https://github.com/charlottexlin/greentown"},
            {icon: linkIcon, text: "greentown.vercel.app", href: "https://greentown.vercel.app/"}
        ]
    },
    {
        image: personalWebsite,
        projectName: "Personal Website",
        featured: false,
        subtitle: "january 2023, web application",
        techStack: " javascript, html, css, react.js, node.js",
        description:
            "I challenged myself to create this website using the React framework. After designing the wireframe, I put the code together from scratch over the course of a week during my winter break. " +
            "I aimed to have this site reflect my personality, as well as serve as a live showcase of my programming and design knowledge.",
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/personal-website", href: "https://github.com/charlottexlin/personal-website"},
            {icon: linkIcon, text: "charlottexlin.com", href: "https://www.charlottexlin.com/"}
        ]
    },
    {
        image: catWent,
        projectName: "Where the Cat Went",
        featured: false,
        subtitle: "december 2022, web project",
        techStack: "javascript, html, css, photoshop, procreate",
        description:
            "A digital art piece about my cat that revolves around the beauty of everyday adventure. This website was my final project for the Digital Art 1 course at NYU. " +
            "It is a client-side-only web application that uses JavaScript DOM manipulations to deliver interactivity. The cat animation was created in Procreate, " +
            "and the backgrounds were created in Adobe Photoshop.",
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/wherethecatwent", href: "https://github.com/charlottexlin/wherethecatwent"},
            {icon: linkIcon, text: "troubled-pumps-colt.cyclic.app", href: "https://troubled-pumps-colt.cyclic.app/"}
        ]
    },
    {
        image: gachaCats,
        projectName: "Gacha Cats!",
        featured: false,
        subtitle: "november 2022, web game",
        techStack: "javascript, html, css, node.js, express.js, bootstrap, mongodb",
        description:
            "Roll! Battle! Collect awesome cats! 'Gacha Cats!' is my final project for the Applied Internet Technology course at NYU, and my first ever complete web project. " +
            "I learned a lot about full-stack development, including database interactions with Mongoose, creating an API with Express and accessing it with Fetch, and and customizing the frontend with Sass. " +
            "All art and design is my own.",
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/gacha-cats", href: "https://github.com/charlottexlin/gacha-cats"},
            {icon: linkIcon, text: "gacha-cats.onrender.com", href: "https://gacha-cats.onrender.com"}
        ]
    },
    {
        image: sliceOfLife,
        projectName: "Slice of Life",
        featured: false,
        subtitle: "february 2022, java application",
        techStack: "java",
        description:
            "Slice of Life is a time slice tracking desktop app, designed to inspire a healthier, more balanced lifestyle. I created this project for HackNYU 2022 within 24 hours. All code and art is by me. " +
            "It won the Disney 'Most Magical Hack' prize.",
        links: [
            {icon: githubIcon, text: "github.com/charlottexlin/slice-of-life", href: "https://github.com/charlottexlin/slice-of-life"},
            {icon: devpostIcon, text: "devpost.com/software/slice-of-life-xui45n", href: "https://devpost.com/software/slice-of-life-xui45n"}
        ]
    },
    {
        image: taskPet,
        projectName: "Task Pet",
        featured: false,
        subtitle: "february 2021, java application",
        techStack: "java",
        description:
            "Task Pet is a desktop assignment tracker app that motivates you to complete your tasks on time with a cute virtual pet. " +
            "It is a fun way for students to stay motivated and make finishing your homework into a game. I created this project for PearlHacks 2021 within 24 hours. " +
            "All code and art is by me. It won the nCino “Why So Serious Hack” prize.",
        links: [
            {icon: devpostIcon, text: "devpost.com/software/task-pet", href: "https://devpost.com/software/task-pet"},
            {icon: driveIcon, text: "Google Drive folder", href: "https://drive.google.com/drive/folders/14pZxuFD_uB9-b12DSJ_5YY_sxDMStP9W"}
        ]
    },
    {
        image: calmCafe,
        projectName: "Calm Café",
        featured: false,
        subtitle: "february 2021, java application",
        techStack: "java",
        description:
            "Calm Café is a desktop app-based mental health solution. This project was created for Hack In Place 2.0 by a 3-person team, with me contributing the code. " +
            "Art and music was by my team members. The program provides a relaxing and aesthetically pleasing digital café space with several mental health resources for users. " +
            "It received the “Best in Category (Health)” prize.",
        links: [
            {icon: driveIcon, text: "Google Drive folder", href: "https://drive.google.com/drive/folders/1Ztsvv1k4bv5jLxhqcArwUE6IBJuRUAmv?usp=sharing"}
        ]
    }
];

export default itemArr;